import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {isRequestSend} from '../../../lib/project';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchRequestRemove} from '../../../backend/apiCalls';
import {Request, RequestDetail, ButtonSize} from '../../../constants/propTypesDefinitions';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveRequest extends React.PureComponent {
    static propTypes = {
        buttonContent: PropTypes.node,
        size: ButtonSize,
        request: PropTypes.oneOfType([Request.isRequired, RequestDetail.isRequired]).isRequired,
        onFinishSuccessful: PropTypes.func,
        buttonClassName: PropTypes.string,
    };

    onFinishSuccessful = () => {
        const {onFinishSuccessful} = this.props;
        if (onFinishSuccessful != null) {
            onFinishSuccessful();
        }
    };

    render() {
        const {request, buttonContent, size, buttonClassName} = this.props;
        const disabled = isRequestSend(request);
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip
                    title={disabled ? 
                        <Trans>Odoslaný dopyt nemožno odstrániť</Trans> :
                        (buttonContent != null ? null : <Trans>Odstrániť</Trans>)
                    }
                >
                    {
                        buttonContent == null ?
                            <Button
                                size={size}
                                type="danger"
                                icon="delete"
                                disabled={disabled}
                                className={buttonClassName ? buttonClassName : ''}
                            />
                            :
                            <Button
                                size={size}
                                type="danger"
                                disabled={disabled}
                                className={buttonClassName ? buttonClassName : ''}
                            >
                                {buttonContent}
                            </Button>
                    }
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.onFinishSuccessful}
            title={<Trans>Odstránenie rozpracovaného dopytu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť rozpracovaný dopyt?</Trans>,
                () => {
                    return createFetchRequestRemove()({id: request.id})
                },
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť rozpracovaný dopyt.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveRequest);